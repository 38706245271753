<template>
  <div :style="{width: width}" class="c-suggestion__container" v-show=isActive @mousedown=selectSuggestion>
  <div class="c-suggestion__suggest-row" v-for="(suggestion, index) in suggestions" :key=index v-bind:class="{'-active': index==currentFocus}"
    v-html="previous_words + ' ' + suggestion.replace(current_word, '<b>'+current_word+'</b>')"></div>
  </div>
</template>

<script>
export default {
  data: function(){
    const vm = this
    return {
      currentFocus: -1,
      suggestions: [],
      isActive: false,
    }
  },
  props: {
    ref_name: String
  },
  computed: {
    width(){
      let width = document.getElementById(this.ref_name).offsetWidth
      if (width == 0){width = 180}
      return(String(width) + 'px')
    },
    current_word(){
      return this.$parent.current_word
    },
    previous_words(){
      let freewords = this.$parent.freewords
      return freewords.slice(0,-1).join(' ')
    }
  },
  methods: {
    setSuggestions(suggestions){
      this.suggestions = suggestions
    },
    selectSuggestion(e){
      const vm = this
      let suggestion = ''
      if (e.type == 'mousedown'){
        if (e.target.tagName == 'B'){
          suggestion = e.target.parentElement.innerText
        }
        else{
        suggestion = e.target.innerText
        }
      }
      else if (e.type == 'keydown'){
        suggestion = vm.suggestions[vm.currentFocus]
      }
      vm.$emit("sg-selected", suggestion)
      vm.setActive(false)
      vm.setFocus(-1)
    },
    focusInc(e){
      this.setFocus(this.currentFocus + 1)
    },
    focusDec(e){
      this.setFocus(this.currentFocus - 1)
    },
    setFocus(n){
      if (n >= this.suggestions.length){n=0}
      else if (n <-1){ n = this.suggestions.length - 1}
      this.currentFocus = n
    },
    setActive(e){
      if (this.isActive != e){this.isActive = e}
    },
  },
}
</script>