import axios from 'axios';
import SuggestionContainer from './components/suggestion_container.vue';

export default {
  data() {
    const vm = this;
    let freeword = document.location.search.substring(1).split('&');
    freeword = freeword ? freeword.find(val => decodeURIComponent(val).indexOf('jobs_search[free_word]') > -1) : undefined;
    return {
      autocomplete: {
        freeword: freeword === undefined ? '' : decodeURIComponent(freeword).split('=')[1],
        handlers: {
          keydown: vm.navigateSuggestions,
          input: vm.fetchSuggestions,
          blur: vm.deactivateSuggestions,
        },
      },
    };
  },
  computed: {
    freewords() {
      return this.autocomplete.freeword.split('\u3000');
    },
    current_word() {
      return this.freewords[this.freewords.length - 1];
    },
  },
  components: {
    SuggestionContainer,
  },
  methods: {
    fetchSuggestions(e) {
      const vm = this;
      vm.autocomplete.freeword = e.target.value;
      const ref = e.target.id;
      if (this.current_word.length > 0) {
        axios
          .get(`/autocomplete?free_word=${this.current_word}`)
          .then((response) => {
            vm.$refs[ref].setSuggestions(response.data);
            vm.$refs[ref].setActive(true);
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
          });
      }
    },
    navigateSuggestions(e) {
      const vm = this;
      const sc = vm.$refs[e.target.id];
      // Down-arrow key pressed
      if (e.keyCode === 40) {
        sc.focusInc();
        return;
      }
      // Up-arrow key pressed
      if (e.keyCode === 38) {
        sc.focusDec();
        return;
      }
      // Enter key pressed
      if (e.keyCode === 13 && sc.currentFocus > -1) {
        e.preventDefault();
        sc.selectSuggestion(e);
        return;
      }

      sc.setFocus(-1);
    },
    activateSuggestions(e) {
      const ref = e.target.id;
      this.$refs[ref].setActive(true);
    },
    deactivateSuggestions(e) {
      const ref = e.target.id;
      this.$refs[ref].setActive(false);
    },
    updateInput(e) {
      this.autocomplete.freeword = e;
    },
  },
};
